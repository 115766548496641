import getData from "@/__main__/get-data.mjs";
import LolProbuildMatches from "@/game-lol/models/lol-probuild-matches.mjs";
import * as API from "@/game-lol/utils/api.mjs";

const PER_PAGE = 20;

function fetchData(params, _searchParams, state) {
  const currPage = state?.page || 1;
  const role = params[0];

  const promises = [];

  promises.push(
    getData(
      API.getProMatchHistory({
        lane: role,
        first: PER_PAGE * currPage,
      }),
      LolProbuildMatches,
      ["lol", "proBuildMatchlist", role],
      {
        shouldFetchIfPathExists: true,
      },
    ),
  );

  return Promise.all(promises);
}

export default fetchData;
